ul.timeline {
  list-style-type: none;
  position: relative;
  padding-left: 8rem;
  .data-view {
    position: absolute;
    margin-left: 3.33rem;
    left: 0;
    color: #FFF;
    z-index: 400;
    margin-top: 1.71875rem
  }
}
ul.timeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 4.1rem;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: ' ';
  background: $bg-color-primary;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  left: 20px;
  width: 95px;
  height: 95px;
  z-index: 400;
}
.circle-grey:before {
  background: #4D5858 !important;
}
