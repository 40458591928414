
@media (max-width: 767.98px) {
  .media-desktop {
    display: none;
  }
  .d-inline-mobile {
    display: inline-block;
  }
  .card-propostas {
    mat-chip {
      border-radius: 0px 0px 8px 0px !important;
    }
  }
  .tab-mobile {
    .mat-mdc-tab-header {
      margin-top: 1rem;
    }
    .mat-mdc-tab-labels {
      display: flex;
      justify-content: center;
    }
    .mat-mdc-tab.mdc-tab {
      height: 34px;
      border: 1px solid #DBDEDE;
      border-radius: 4px;
      margin-inline: 0.5rem;
      background-color: #FFF;
      font-size: 12px;
      padding: 1px;
      line-height: 18px;
      .mdc-tab-indicator {
        display: none;
      }
    }
    .mdc-tab.mat-mdc-tab.mat-mdc-focus-indicator.mdc-tab--active.mdc-tab-indicator--active {
      border: 1px solid #0074FF;
    }
  }
  .height-sem-proposta {
    height: calc(100vh - 250px);
  }

  .gestao-board {
    .mat-expansion-panel-body {
      padding: 0 0px 0px !important;
    }
  }
}

@media (min-width: 768px) {
  .media-mobile {
    display: none;
  }
  .height-sem-proposta {
    height: calc(100vh - 250px);
  }
}
