 $color-grey-medium: #4D4D4D;
$color-grey-dark: #333333;
$color-texto-secundario: #717979;

.anchor-link {
  font-family: 'Lato-Regular', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
}

.h3-us {
  font-family: 'Lato-Regular', sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: $color-grey-medium !important;
}

.desktop_title_h2 {
  font-family: 'Verdana' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  color: #212529 !important;
}

 .desktop_title_h3 {
   font-family: Verdana !important;
   font-size: 24px !important;
   font-weight: 700 !important;
   line-height: 36px !important;
   letter-spacing: 0px !important;
   color: #212529 !important;
 }
 .desktop_title_h4 {
   font-family: 'Lato-Regular', sans-serif !important;
   font-size: 20px !important;
   font-weight: 800 !important;
   line-height: 30px !important;
   text-align: left !important;
   text-underline-position: from-font !important;
   text-decoration-skip-ink: none !important;
 }

.desktop_body_P1 {
  font-family: 'Lato-Regular', sans-serif;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 32px !important;
  color: #333333 !important;
}

.desktop_body_P4 {
  font-family: 'Lato-Regular', sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: $color-grey-dark !important;
  &--grey-medium {
    color: $color-grey-medium;
  }
  &--color-segundario {
    color: $color-texto-secundario !important;
  }
}
 .desktop_body_P4_black {
  font-family: 'Lato-Regular', sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #000 !important;
}

.desktop_body_p5 {
   font-family: 'Lato-Regular', sans-serif !important;
   font-size: 16px !important;
   font-weight: 700 !important;
   line-height: 24px !important;
   letter-spacing: 0px !important;
   text-align: center !important;
  &--text-start {
    text-align: start !important;
  }
 }

.desktop_body_P7 {
  font-family: 'Lato-Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
.desktop_body_P10 {
  font-family: 'Lato-Regular', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}
.desktop_body_P11 {
 font-family: 'Lato-Regular', sans-serif;
 font-size: 12px;
 font-weight: 700;
 line-height: 18px;
 letter-spacing: 0px;
}
.desktop_body_small {
  font-family: "Lato-Regular", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
}

mat-chip {
  font-family: 'Lato-Regular', sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
}
.form-group > label {
  margin-bottom: calc(0rem + 3px);
  font-family: 'Lato-Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #4D5858 !important
}

// FONT WEIGHT
.fw-800 {
  font-weight: 800;
}
.fw-700 {
  font-weight: 700 !important;
}

// CORES TEXTO
.color-azul-caneta {
  color: #0353AE;
}
.color-black {
  color: #000;
}
.color-grafite {
   color: #1A1A1A !important;
 }
.color-cinza-lobo {
   color: $color-grey-dark;
 }
.color-cinza-forte {
  color: $color-grey-medium !important;
}
.color-cinza-da-manha {
  color: #BDBDBD;
}
.color-cinza-do-gelo {
  color: #B8BCBC;
}
.color-cinza-da-mata {
  color: #A9A9A9 !important;
}
.color-cinza-da-noite {
  color: #4D5858 !important;
}
.color-vermelho-pjus {
  color: #B5252F !important;
}
.color-primario {
  color: #0074FF !important;
}
.color-sucesso {
  color: #0E8557;
}
 .color-white {
  color: #FFF !important;
}
.txt-nome-arquivo-upload {
  font-size: 14px;
  max-width: 12ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// LIMITADOR DE CARACTER
 .font-verdana {
   font-family: 'Verdana', sans-serif !important;
 }

.text-limit-30 {
  max-width: 30ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.font-size-1rem {
  font-size: 1rem !important;
}

.txt-titulo-erro {
  font-size: 48px;
  line-height: 64px;
}
.texto-2 {
  font-family: 'Lato-Regular', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
