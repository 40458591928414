.auth-card {
  @extend .card;
  background-color: #FFF;
  .card-header {
    padding: 24px 48px 12px 48px !important;
  }
  .card-body {
    padding: 12px 48px 24px 48px !important;
  }
}
