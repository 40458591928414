.bg-warning {
  color: #000 !important;
}
.border-orange {
  border-color: #FF9900 !important;
}
.bg-orange {
  background-color: #FF9900 !important;
}
.bg-cinza-noite {
  background-color: #4D5858 !important;
}
.bg-cinza-do-gelo {
  background-color: #B8BCBC;
}
