.switch-toggle-3 {
  height: 48px;
  width: 163px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 !important;
  background-color: #FFF;
  border: solid 2px #D9D9D9;
  border-radius: 8px;
  padding: 4px;

  input {
    position: absolute;
    left: 0;
    opacity: 0;

    &+label {
      z-index: 2;
      text-align: center;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:checked+label {
      color: white;
      font-weight: 400;
      &~a{
        background-color: #0074FF;
      }
    }
  }

  a {
    display: block;
    transition: all 0.2s ease-out;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    border-radius: 4px;
    background-color: transparent;
  }

  label {
    color: #333333;
    font-weight: 700;
    text-align: center;
  }

  &.switch-blue {
    input:checked+label~a {
      background-color: #0353AE
    }
  }

  label:nth-child(2):nth-last-child(4),
  label:nth-child(2):nth-last-child(4)~label,
  label:nth-child(2):nth-last-child(4)~a {
    width: 50%;
  }

  label:nth-child(2):nth-last-child(4)~input:checked:nth-child(3)+label~a {
    left: 50%;
  }

  label:nth-child(2):nth-last-child(6),
  label:nth-child(2):nth-last-child(6)~label,
  label:nth-child(2):nth-last-child(6)~a {
    width: 33.33%;
  }

  label:nth-child(2):nth-last-child(6)~input:checked:nth-child(3)+label~a {
    left: 33.33%;
  }

  label:nth-child(2):nth-last-child(6)~input:checked:nth-child(5)+label~a {
    left: 66.66%;
  }

  label:nth-child(2):nth-last-child(8),
  label:nth-child(2):nth-last-child(8)~label,
  label:nth-child(2):nth-last-child(8)~a {
    width: 25%;
  }

  label:nth-child(2):nth-last-child(8)~input:checked:nth-child(3)+label~a {
    left: 25%;
  }

  label:nth-child(2):nth-last-child(8)~input:checked:nth-child(5)+label~a {
    left: 50%;
  }

  label:nth-child(2):nth-last-child(8)~input:checked:nth-child(7)+label~a {
    left: 75%;
  }

  label:nth-child(2):nth-last-child(10),
  label:nth-child(2):nth-last-child(10)~label,
  label:nth-child(2):nth-last-child(10)~a {
    width: 20%;
  }

  label:nth-child(2):nth-last-child(10)~input:checked:nth-child(3)+label~a {
    left: 20%;
  }

  label:nth-child(2):nth-last-child(10)~input:checked:nth-child(5)+label~a {
    left: 40%;
  }

  label:nth-child(2):nth-last-child(10)~input:checked:nth-child(7)+label~a {
    left: 60%;
  }

  label:nth-child(2):nth-last-child(10)~input:checked:nth-child(9)+label~a {
    left: 80%;
  }

  label:nth-child(2):nth-last-child(12),
  label:nth-child(2):nth-last-child(12)~label,
  label:nth-child(2):nth-last-child(12)~a {
    width: 16.6%;
  }

  label:nth-child(2):nth-last-child(12)~input:checked:nth-child(3)+label~a {
    left: 16.6%;
  }

  label:nth-child(2):nth-last-child(12)~input:checked:nth-child(5)+label~a {
    left: 33.2%;
  }

  label:nth-child(2):nth-last-child(12)~input:checked:nth-child(7)+label~a {
    left: 49.8%;
  }

  label:nth-child(2):nth-last-child(12)~input:checked:nth-child(9)+label~a {
    left: 66.4%;
  }

  label:nth-child(2):nth-last-child(12)~input:checked:nth-child(11)+label~a {
    left: 83%;
  }

  animation: webkitSiblingBugfix infinite 1s;
}

