$bg-color-primary: #0074FF;
$text-color-primary: #FFFFFF;
$md-pjusthemeprimary: (
  50 : #e3f2fd,
  100 : #bbdefb,
  200 : #90caf9,
  300 : #64b5f6,
  400 : #42a5f5,
  500 : #0074FF,
  600 : #1e88e5,
  700 : #1976d2,
  800 : #1565c0,
  900 : #0d47a1,
  A100 : #82b1ff,
  A200 : #448aff,
  A400 : #2979ff,
  A700 : #2962ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);
