.form-select {
    background-size: 16px;
    min-height: 38px;
}

.form-label {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: #717979;
}
.mat-mdc-radio-button .mdc-form-field {
  color: #717979 !important;
}
