@font-face {
  font-family: "Oswald-Bold";
  src: url("../fonts/Oswald-Bold.ttf");
}

@font-face {
  font-family: "Lato-Black";
  src: url("../fonts/Lato-Black.ttf");
}

@font-face {
  font-family: "Lato-Black-Italic";
  src: url("../fonts/Lato-BlackItalic.ttf");
}

@font-face {
  font-family: "Lato-Bold";
  src: url("../fonts/Lato-Bold.ttf");
}

@font-face {
  font-family: "Lato-Bold-Italic";
  src: url("../fonts/Lato-BoldItalic.ttf");
}

@font-face {
  font-family: "Lato-Italic";
  src: url("../fonts/Lato-Italic.ttf");
}

@font-face {
  font-family: "Lato-Light";
  src: url("../fonts/Lato-Light.ttf");
}

@font-face {
  font-family: "Lato-Light-Italic";
  src: url("../fonts/Lato-LightItalic.ttf");
}

@font-face {
  font-family: "Lato-Regular";
  src: url("../fonts/Lato-Regular.ttf");
}

@font-face {
  font-family: "Lato-Thin";
  src: url("../fonts/Lato-Thin.ttf");
}

@font-face {
  font-family: "Lato-Thin-Thin";
  src: url("../fonts/Lato-ThinItalic.ttf");
}

.font-lato {
  font-family: 'Lato-Regular';
}
