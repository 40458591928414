:root {
  --vermelho-pjus: #0074FF;
  --azul-bootstrap: #86b7fe;
  --black: #000000;
  --background: #FFF;
  --background-bottom: rgba(0, 0, 0, 0.32);
  --color-primary: var(--vermelho-pjus);
  --color-secondary: var(--vermelho-pjus);
  --color-checkbox-border-input-active: var(--vermelho-pjus);
  --color-input-border-text: #ced4da;
  --background-hover-pjus: rgb(51, 148, 255, 0.5);
  --color-multiselect-border-field-focus: #86b7fe;
  --outline-color-focused: #86b7fe;
  --color-focused: #86b7fe !important;
  --color-action-hover: var(--azul-bootstrap);
  --color-brand-01-lightest: #FFF;
  --color-input-background-color-text: var(--background);
  --color-datepicker-range-color-focused: var(--azul-bootstrap);
  --color-calendar-background-color-mobile-overlay: var(--background-bottom);
}

.po-disclaimer-remove:hover {
  background-color: var(--background-hover-pjus);
}
.po-field-container-content {
  padding: 0px;
}

.po-listbox-list {
  padding: 0;
}

:hover:is(.po-item-list__action, .po-item-list__check, .po-item-list__option):not(.po-item-list__disabled, .po-item-list__danger) {
  background-color: var(--background-hover-pjus);
  cursor: pointer;
}

.po-item-list__check:active,
.po-item-list__check:hover:active {
  background-color: var(--background-hover-pjus);
  cursor: pointer;
}

.po-multiselect-input {
  min-height: 2.4rem;
}

.po-input {
  min-height: 2.4rem;
}
.po-input:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, .25);
}

.po-field-icon.po-icon-input:not(.po-icon-eye-off), po-clean.po-icon-input span.po-field-icon {
  color: var(--black)!important;
}

.po-multiselect-input:hover:not(.po-multiselect-input-disabled) {
  border-color: #86b7fe;
  background-color: #FFF;
}
.po-multiselect-input-static .po-multiselect-input-placeholder {
  padding: 2px 8px;
  color: #7c7c7c;
}

.po-multiselect-input {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  -webkit-appearance: none;
  appearance: none;
  border-radius: .375rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.po-datepicker-range-field {
  margin: 0;
}
div.po-datepicker-range-field-focused {
  border-color: rgba(13, 110, 253, .25);
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, .25);
  outline-color: #86b7fe;
  outline-width: 0px;
  outline-style: solid;
  outline-offset: 0px;
}

.po-datepicker-calendar-overlay {
  background-color: var(--color-calendar-background-color-mobile-overlay);
}
.po-checkbox:hover {
  border-color: var(--color-checkbox-border-input-active, #86b7fe) !important;
  box-shadow: 0 0 0 4px #86b7fe !important;
}
