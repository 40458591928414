table {
  th {
    font-family: 'Lato-Regular', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #4D5858;
  }
}
.table-th-w25 {
  width: 25px;
}
.table-th-w100 {
  width: 100px;
}

.ngx-datatable {
  .datatable-header {
    background-color: #F7F7F7;
    .datatable-header-cell {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
      color: #B8BCBC;
    }
  }
  .empty-row {
    text-align: center !important;
    display: block !important;
    width: 100% !important;
    font-size: 16px !important;
    margin-top: 16px !important;
  }
  &.datatable-row-hover{
    datatable-body-row {
      background-color: #FFFFFF;
      padding: 5px 0px;
      &:hover {
        background-color: rgb(0 0 0 / 5%);
        cursor: pointer;
      }
    }
    datatable-body-cell {
      //align-items: center;
    }
  }
}