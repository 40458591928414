@keyframes fadeOut {
  from {
    opacity: 1;
    transform: scale(3);
  }

  to {
    opacity: 0;
    transform: scale(0);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
